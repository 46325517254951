import React from 'react';

function OpenHoursBox(props) {
    return (
        <div className="rn-address bg_color--3">
            <div className="inner d-flex justify-content-center align-items-center flex-column">

                <h4 className="title text-center d-inline-block mt--15">Schedule of tours of Sitarjevec Litija Mine</h4>

                <p className="mt--15"><strong>Thursday </strong> </p>
                <p>9 to 12h and 15 to 18h</p>

                <p className="mt--15"><strong>Friday </strong> </p>
                <p>9 to 12h and 15 to 18h</p>

                <p className="mt--15"><strong>Saturday</strong> </p>
                <p>9 to 12h and 15 to 18h</p>

                <p className="mt--15"><strong>Sunday </strong> </p>
                <p>9 to 12h and 15 to 18h</p>

                <small className="text-center mt--30 saturday-special-offer"><strong>NEW! It is possible to visit the Export Pit of the mine without prior notice every Saturday at 10:00 AM.</strong></small>

                <small className="text-center mt--30">Required prior order. Groups can be ordered outside the specified hours.</small>
            </div>
        </div>
    );
}

export default OpenHoursBox;