export const transportRowPrices = [
    {
        title: 'Children and primary school children (4 - 15 years)',
        price: '7 € per person',
        group: '6 € per person'
    },
    {
        title: 'Students, pensioners, disabled people',
        price: '8 € per person',
        group: '6 € per person'
    },
    {
        title: 'Adults',
        price: '10 € per person',
        group: '8 € per person'
    },
    {
        title: 'With a dwarf through the Sitarjevec Mine (from 0 – 99 year)',
        price: '10 € per person',
        group: '8 € per person'
    },
    {
        title: 'Sitarjevec snack (surcharge)',
        price: '11 € per person',
        group: ''
    },
    {
        title: 'Dwarf "šmorn" (surcharge)',
        price: '10 € per person',
        group: ''
    },
];

export const mainRowPrices = [
    {
        title: 'Children and primary school children (10 - 15 years)',
        price: '15 € per person',
    },
    {
        title: 'Students, pensioners, disabled people',
        price: '21 € per person',
    },
    {
        title: 'Adults',
        price: '23 € per person',
    },
    {
        title: "Sitarjevec snack (surcharge)",
        price: '11 € per person',
    },
    {
        title: 'Dwarf "šmorn" (surcharge)',
        price: '10 € per person',
    }
];

export const combinationOfRowsPricing = [
    {
        title: 'Children and primary school children (10 - 15 years)',
        price: '22 € per person',
    },
    {
        title: 'Students, pensioners, disabled people',
        price: '24 € per person',
    },
    {
        title: 'Adults',
        price: '29 € per person',
    },
    {
        title: 'Sitarjevec snack (surcharge)',
        price: '11 € per person',
    },
    {
        title: 'Dwarf "šmorn" (surcharge)',
        price: '10 € per person',
    },
];