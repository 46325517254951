import React from 'react';

function ReservationScheduleBox(props) {
    return (
        <div className="rn-address bg_color--3">
            <div className="inner d-flex justify-content-center align-items-center flex-column">

                <h4 className="title text-center d-inline-block mt--15">Reservations</h4>

                <small className="text-center mb--30">Guests can book a visit during TIC Litija office hours.</small>

                <p><strong>Monday</strong> </p>
                <p>Closed</p>

                <p className="mt--15"><strong>Tuesday</strong> </p>
                <p>10 am to 16</p>

                <p className="mt--15"><strong>Wednesday</strong> </p>
                <p>9 am  to 17</p>

                <p className="mt--15"><strong>Thursday</strong> </p>
                <p>Closed</p>

                <p className="mt--15"><strong>Friday</strong> </p>
                <p>9 am to 15</p>

                <p className="mt--15 text-center"><strong>Saturday</strong> </p>
                <p className="text-center">
                    <span>9 am to 12</span>
                    <div className="saturday-visit-text saturday-special-offer">
                        Present at the location of the mine reception.
                    </div>
                </p>

                <p className="mt--15"><strong>Sunday and holidays </strong> </p>
                <p>Closed</p>

            </div>
        </div>
    );
}

export default ReservationScheduleBox;