import React from 'react';
import SEO from "../../components/en/seo/Seo";
import Footer from "../../components/en/layouts/Footer";
import Header from "../../components/en/headers/Header";
import ServicesPricing from "../../components/en/pricing/ServicesPricing";
import PricingHeader from "../../components/en/pricing/PricingHeader";

function PriceList(props) {
    return (
        <>
            <SEO
                title="Pricing List"
                description="Prices of visits to the Sitarjevec Mine. Pre-order required. Groups can be ordered outside the stated hours. Children up to 3 years free. We organize tours within the schedule of regular visits for groups of 4 people or more, outside the schedule of regular visits for groups of 6 people or more."
            />
            <Header />
            <PricingHeader/>
            <ServicesPricing/>
            <Footer/>
        </>
    );
}

export default PriceList;